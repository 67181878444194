<template>
  <questionnaire
    v-if="form !== null"
    :form="form"
    :readOnly="true"
    :loading="loading"
    :preview="true"
  ></questionnaire>
</template>

<script>
import { mapActions } from 'vuex';
import questionnaire from '@/components/forms/questionnaire/questionnaire.vue';

export default {
  components: {
    questionnaire
  },

  data: () => ({
    form: null,
    loading: true
  }),

  async mounted() {
    const formId = this.$route.params.id;
    await this.loadCustomSettings('hays');
    this.form = await this.loadForm(formId);
    this.loading = false;
  },

  methods: {
    ...mapActions({
      loadForm: 'forms/loadForm',
      loadCustomSettings: 'forms/loadCustomSettings'
    })
  }
};
</script>

<style></style>
